import React from 'react';
import { FavoriteCards } from './favorite-cards';

export const FavoriteSection = (props: any) => {
   return (
      <div className="w-full py-10 px-5">
         <FavoriteCards {...props} />
      </div>
   );
};
