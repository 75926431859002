import React from 'react';
import { IsMobile } from '@matix/utils';

interface Props {
   contactInfo: React.ReactElement;
   openHours: React.ReactElement;
   contactForm: React.ReactElement;
}
export const ContactSection = (props: Props) => {
   return (
      <div className="w-full lg:h-full">
         <div className="flex py-10 justify-center">
            {IsMobile() ? (
               <div className="flex-col mx-auto space-y-5">
                  <div className="px-8">{props.contactInfo}</div>
                  <div className="px-2 mx-auto">{props.contactForm}</div>
                  <div className="px-8">{props.openHours}</div>
               </div>
            ) : (
               <>
                  <div className="flex flex-col px-8 md:w-full lg:w-1/2 xl:w-1/3">
                     {props.contactInfo}
                     {props.openHours}
                  </div>
                  <div className="px-8 md:w-full lg:w-1/2 xl:w-1/3">{props.contactForm}</div>
               </>
            )}
         </div>
      </div>
   );
};
