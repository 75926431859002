export const IsMobile = () => {
  if (isSSR()) return false;

  return window.innerWidth <= 768;
};

export const isSSR = () => {
  return typeof window === 'undefined';
};

export const IsPreview = () => {
  if (isSSR()) return false;

  if (window.location && window.location.origin) {
    return window.location.pathname === '/admin/';
  } else {
    return false;
  }
};
