import React from 'react';

interface Props {
   mapTitle: string;
   mapSrc: string;
   className: string;
}

export const Map = (props: Props) => {
   return (
      <div className={props.className}>
         <iframe
            width="100%"
            height="100%"
            title={props.mapTitle}
            id="gmap_canvas"
            src={props.mapSrc}
            frameBorder="0"
            scrolling="no"
            marginHeight={0}
            marginWidth={0}
         ></iframe>
      </div>
   );
};
