import React from 'react';
import { MdStringRenderer } from '@matix/utils';
import { ImageProps, Image } from '@matix/ui';
import { Heart } from '@matix/ui';

export interface CardProps {
   headerTitle: string;
   img: ImageProps;
   bodyTitle: string;
   bodyText: string;
   bodyHeart: string;
   footerBtn: string;
}

export const Card = (props: CardProps) => {
   return (
      <div className="h-full flex flex-col hover:shadow-2xl">
         {/* Card Header */}
         <div className="relative border-1 border-black">
            <div className="absolute z-10 bg-black opacity-80 py-2 px-3 ml-3 mt-3">
               <div className="text-white">
                  <MdStringRenderer source={props.headerTitle} />
               </div>
            </div>
            <Image {...props.img} className="h-full" />
         </div>

         {/* Card Body */}
         <div className="h-full p-6 flex flex-col border-1 border-black bg-white ">
            <div className="text-2xl font-bold mb-4">
               <MdStringRenderer source={props.bodyTitle} />
            </div>
            <div className="h-full">
               <MdStringRenderer source={props.bodyText} />
            </div>
            <div className="flex flex-row mt-3">
               <Heart className="w-4 mr-2 h-6" />
               <MdStringRenderer source={props.bodyHeart} />
            </div>
         </div>

         {/* Card Footer */}
         <div className="p-4 border-1 border-black bg-gray-200 ">
            <div className="flex justify-center items-center h-full">
               <div className="bg-primary text-white font-bold hover:bg-secondary hover:text-black cursor-pointer rounded-xl p-3 ">
                  <MdStringRenderer source={props.footerBtn} />
               </div>
            </div>
         </div>
      </div>
   );
};
