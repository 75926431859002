import React, { useContext, useState } from 'react';
import { CaretSvg } from '../svg';
import c from 'classnames';
import { LinkMatix, MdStringRenderer } from '@matix/utils';
import { StoreContext } from '../contexts';
import { ImageProps, Image } from '@matix/ui';

interface Props {
   link?: string;
   slug: string;
   title?: string;
   group: any[];
   mainHeaderImage: ImageProps;
   showAllText: string;
   mainTitleText?: string;
   subTitleText?: string;
   children: React.ReactNode;
}
export const HeaderCategory = (props: Props) => {
   const [open, setOpen] = useState(false);
   const { slugify } = useContext(StoreContext);
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         {/* High level category containing a submenu */}
         {!props.group && props.children ? (
            <div className="">
               <button
                  onClick={() => setOpen(!open)}
                  type="button"
                  className={c(
                     'outline-none group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900',
                     open ? 'text-gray-400' : 'text-gray-600'
                  )}
               >
                  <MdStringRenderer source={props.link} />
                  <CaretSvg />
               </button>

               {/* Mega menu */}
               <div
                  className={c(
                     'absolute w-full mt-2 -ml-5 sm:ml-0 sm:mt-7 ease-in-out px-2 sm:px-0 sm:left-0 transition duration-200',
                     open ? 'opacity-100 z-50' : 'opacity-0 -z-10'
                  )}
               >
                  <div className="shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden bg-white ">
                     <div className="relative opacity-100 flex flex-col px-5 py-6 sm:p-8">
                        <div className="w-full hidden xl:block text-black-600 mb-8">
                           <h2 className="font-bold text-xl sm:text-3xl">{props.mainTitleText}</h2>
                           <p className="text-lg font-light">{props.subTitleText}</p>
                        </div>
                        <div className="flex flex-col sm:flex-row sm:flex-1 sm:justify-around">
                           <div className="flex-initial px-20 hidden xl:block">
                              <Image className="h-96 w-96 object-cover m-auto" {...props.mainHeaderImage} />
                           </div>
                           {props.children}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         ) : !props.group && !props.children ? (
            //  Top level with no sub menu
            <div className="text-base font-medium text-gray-500 hover:text-gray-900">
               <MdStringRenderer source={props.link} />
            </div>
         ) : props.group ? (
            // Section where we create the dynamic content of categories/vendors/others
            <div className="flex flex-col flex-grow border-gray-200 pb-7 border-b sm:border-b-0 sm:border-l sm:pl-7 sm:pb-0 ">
               <div className="w-full text-xl text-black mb-2 font-semibold">
                  <MdStringRenderer source={props.link} />
               </div>
               {props.group &&
                  props.group.map((group, idx) => (
                     <div
                        className={c(
                           'capitalize w-full text-base ml-2 text-gray-500 mb-2 font-semibold',
                           idx === props.group.length - 1 ? 'flex-grow' : idx
                        )}
                     >
                        <LinkMatix to={props.slug + slugify(group.fieldValue) + '/page/1'} role="list">
                           <MdStringRenderer source={group.fieldValue} />
                        </LinkMatix>
                     </div>
                  ))}
            </div>
         ) : null}
      </>
   );
};
