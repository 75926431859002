import React from 'react';
import { Card, CardProps } from './card';

interface Props {
   cards: CardProps[];
}

export const CardGroup = (props: Props) => {
   return (
      <div className="bg-gray-100 w-full py-16">
         <div className="mx-4 lg:mx-10 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {props.cards.map((card: CardProps, idx: number) => (
               <div key={idx} className="h-full">
                  <Card {...card} />
               </div>
            ))}
         </div>
      </div>
   );
};
