import { Image, ImageProps } from '@matix/ui';
import { LinkMatix } from '@matix/utils';
import React from 'react';
import { FavoriteHearth } from './favorite-hearth';
import { MdStringRenderer } from '@matix/utils';
export interface ProductCardProps extends React.HTMLAttributes<HTMLDivElement> {
   name: string;
   hideHeart?: boolean;
   category?: string;
   imgMain: ImageProps;
   description?: string;
   applications?: string[];
   imgSecondaries?: ImageProps[];
   productLink: string;
   provider?: string;
   logo?: React.ReactNode;
   slug?: string;
   btnDetailText: string;
}
export const ProductSummaryCard = (props: ProductCardProps) => {
   const { name, category, imgMain, productLink, logo, provider, btnDetailText } = props;

   return (
      <div className="grid grid-cols-1 bg-gray-100 shadow-xl">
         {logo ? (
            <div key="1" className="col-start-1 row-start-1 col-span-1 z-10">
               <div className="relative mx-auto">
                  <div className="absolute z-10 bg-white opacity-80 py-2 px-3 mt-3 ml-3">{logo}</div>
               </div>
            </div>
         ) : (
            <div key="1" className="col-start-1 row-start-1 col-span-1 z-10" />
         )}

         <div key="2" className="relative z-10 col-start-1 row-start-1 px-4 pb-4 mt-40 pt-40 border-gray-200 bg-gradient-to-t from-black">
            {category ? (
               <div className="text-sm font-medium text-white">
                  <MdStringRenderer source={category} />
               </div>
            ) : null}
            <div className="text-xl font-semibold text-white">
               <MdStringRenderer source={name} />
            </div>
         </div>

         <div key="3" className="col-start-1 row-start-1">
            <div className="w-full h-full">
               <div className="relative h-full">
                  {!props.hideHeart ? (
                     <div className="absolute z-10 right-5 -mt-8">
                        <FavoriteHearth
                           className="w-16 h-16"
                           favorite={{ url: productLink, image: { ...imgMain }, productName: name, provider: provider, category: category }}
                        ></FavoriteHearth>
                     </div>
                  ) : null}
                  <div className="absolute w-full h-full overflow-hidden">
                     <Image {...imgMain} className="inset-0 h-96 object-cover bg-gray-100" />
                  </div>
               </div>
            </div>
         </div>

         <div key="4" className="col-start-1 row-start-4 py-3 px-auto text-center bg-gray-100">
            <LinkMatix to={productLink}>
               <button
                  type="button"
                  className="shadow bg-primary focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded hover:bg-secondary hover:text-black"
               >
                  <MdStringRenderer source={btnDetailText} />
               </button>
            </LinkMatix>
         </div>
      </div>
   );
};
