import React from 'react';

export const PermaconSvg = (props: React.SVGProps<SVGSVGElement>) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="392.2" height="59.1" viewBox="0 0 392.2 59.1" {...props}>
         <g stroke="#000" strokeOpacity="1">
            <g stroke="#000" strokeOpacity="1">
               <g>
                  <path d="M23.3 0L0 6.2 0 23.3 23.3 17.1 70 23.3 70 6.2z" className="st0 fill-current text-gray-200"></path>
                  <path d="M46.7 42L0 35.8 0 52.9 46.7 59.1 70 52.9 70 35.8z" className="st1 fill-current text-red-700"></path>
                  <path
                     fill="#000"
                     fillOpacity="1"
                     stroke="#000"
                     strokeOpacity="1"
                     d="M98 52.9V6.3h12.8c11 0 16.3 5.2 16.3 13.9 0 8.8-5.4 13.9-16.3 13.9h-3.9v18.7H98zm8.9-26.1h4.8c4.4 0 6.6-2.2 6.6-6.6 0-4.4-2.2-6.6-6.6-6.6h-4.8v13.2z"
                     className="st2"
                  ></path>
                  <path
                     fill="#000"
                     fillOpacity="1"
                     stroke="#000"
                     strokeOpacity="1"
                     d="M133.9 52.9V6.3h24.4v7.9h-15.5v10.7h11.8v7.9h-11.8V45h16.1v7.9h-25z"
                     className="st2"
                  ></path>
                  <path
                     fill="#000"
                     fillOpacity="1"
                     stroke="#000"
                     strokeOpacity="1"
                     d="M186.7 52.9l-7.7-19h-4.3v19h-8.9V6.3h12.9c10.7 0 15.8 5.2 15.8 13.9 0 5.8-2.1 10-7 11.9l8.8 20.8h-9.6zm-12-26.4h4.6c3.9 0 6.3-1.9 6.3-6.5 0-4.5-2.4-6.5-6.3-6.5h-4.6v13z"
                     className="st2"
                  ></path>
                  <path
                     fill="#000"
                     fillOpacity="1"
                     stroke="#000"
                     strokeOpacity="1"
                     d="M202.7 52.9V6.3h12.5l8.1 30.6h.1l8.1-30.6H244v46.6h-7.6V16.2h-.1l-9.5 36.7h-6.7l-9.5-36.7h-.1v36.7h-7.8z"
                     className="st2"
                  ></path>
                  <path
                     fill="#000"
                     fillOpacity="1"
                     stroke="#000"
                     strokeOpacity="1"
                     d="M249.7 52.9l11.6-46.6h11.4L284 52.9h-8.5l-2.3-9.8h-12.3l-2.5 9.8h-8.7zm12.8-17.7h8.8L267 16.3h-.1l-4.4 18.9z"
                     className="st2"
                  ></path>
                  <path
                     fill="#000"
                     fillOpacity="1"
                     stroke="#000"
                     strokeOpacity="1"
                     d="M297.4 39c0 5.2 2.3 6.6 5.8 6.6s5.8-1.4 5.8-6.6v-3.1h8.9v1.9c0 11.5-6.1 15.6-14.7 15.6s-14.7-4.1-14.7-15.6V21.2c0-11.5 6.1-15.6 14.7-15.6s14.7 4.1 14.7 15.6v.1H309V20c0-5.2-2.3-6.6-5.8-6.6-3.6 0-5.8 1.4-5.8 6.6v19z"
                     className="st2"
                  ></path>
                  <path
                     fill="#000"
                     fillOpacity="1"
                     stroke="#000"
                     strokeOpacity="1"
                     d="M324.9 21.2c0-11.5 6.1-15.6 14.7-15.6 8.7 0 14.7 4.1 14.7 15.6v16.7c0 11.5-6.1 15.6-14.7 15.6s-14.7-4.1-14.7-15.6V21.2zm8.9 17.8c0 5.2 2.3 6.6 5.8 6.6 3.6 0 5.8-1.4 5.8-6.6V20c0-5.2-2.3-6.6-5.8-6.6s-5.8 1.4-5.8 6.6v19z"
                     className="st2"
                  ></path>
                  <path
                     fill="#000"
                     fillOpacity="1"
                     stroke="#000"
                     strokeOpacity="1"
                     d="M361.8 52.9V6.3h9.8L384 33.9h.1V6.3h8.1v46.6h-8.5L370 22.8h-.1v30.1h-8.1z"
                     className="st2"
                  ></path>
               </g>
            </g>
         </g>
      </svg>
   );
};
