import React, { useState } from 'react';
import { MdStringRenderer } from '@matix/utils';

interface Props {
   firstName: string;
   lastName: string;
   requiredText: string;
   phone: string;
   email: string;
   message: string;
   subject: string;
   btnText: string;
   succes: string;
   error: string;
}

export const Form = (props: Props) => {
   const [formState, setState] = useState({
      name: '',
      lastName: '',
      from: '',
      phone: '',
      text: '',
      subject: '',
      mielBot: '',
      required: false,
      attachment: null,
      submitting: false,
      valid: null,
   });
   const encode = (data) => {
      return Object.keys(data)
         .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
         .join('&');
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      if (formState.mielBot !== '') {
         return; // form filled by bot.
      }

      if (!formState.name || !formState.from) {
         setStateI({ required: true });
         return;
      }

      setStateI({ submitting: true });

      fetch('/.netlify/functions/sendMail', {
         method: 'POST',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         body: encode({ 'form-name': 'contact', ...formState }),
      }).then((e) => {
         let isValid = e.status === 200 ? true : false;

         setStateI({
            name: '',
            lastName: '',
            subject: '',
            from: '',
            phone: '',
            text: '',
            attachment: null,
            submitting: false,
            valid: isValid,
         });
      });
   };

   function setStateI(state) {
      setState((currentState) => ({ ...currentState, ...state }));
   }

   const handleChange = (e) => {
      setStateI({ [e.target.name]: e.target.value });
   };

   return (
      <div className="px-3 z-10">
         <form className="relative bg-white rounded border-2 px-8 pt-6 mt-6 pb-8 z-10" id="contactForm" onSubmit={handleSubmit}>
            <div style={{ display: 'none' }}>
               <input className="form-control" type="text" name="miel-bot" value={formState.mielBot} onChange={handleChange} />
            </div>
            <div className="flex flex-wrap -mx-3 mb-6 z-10">
               <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 z-10">
                  <div className="block tracking-wide text-gray-700 text-xs font-bold mb-2 z-10">
                     <MdStringRenderer source={props.firstName} />
                  </div>
                  <input
                     className="appearance-none block w-full bg-formbg text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                     id="grid-first-name"
                     type="text"
                     name="name"
                     value={formState.name}
                     onChange={handleChange}
                  />
                  {formState.required ? (
                     <div className="text-xs italic">
                        <MdStringRenderer source={props.requiredText} />
                     </div>
                  ) : null}
               </div>
               <div className="w-full md:w-1/2 px-3 z-10">
                  <div className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                     <MdStringRenderer source={props.lastName} />
                  </div>
                  <input
                     className="appearance-none block w-full bg-formbg text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                     id="grid-last-name"
                     type="text"
                     name="lastName"
                     value={formState.lastName}
                     onChange={handleChange}
                  />
               </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6 z-10">
               <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 z-10">
                  <div className="block tracking-wide text-gray-700 text-xs font-bold mb-2 z-10">
                     <MdStringRenderer source={props.phone} />
                  </div>
                  <input
                     className="appearance-none block w-full bg-formbg text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                     id="phone"
                     type="tel"
                     name="phone"
                     value={formState.phone}
                     onChange={handleChange}
                  />
                  {formState.required ? (
                     <div className="text-xs italic">
                        <MdStringRenderer source={props.requiredText} />
                     </div>
                  ) : null}
               </div>
               <div className="w-full md:w-1/2 px-3 z-10">
                  <div className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                     <MdStringRenderer source={props.email} />
                  </div>
                  <input
                     className="appearance-none block w-full bg-formbg text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                     id="email"
                     type="email"
                     name="from"
                     value={formState.from}
                     onChange={handleChange}
                  ></input>
                  {formState.required ? (
                     <label className="text-red-600 text-xs italic">
                        <MdStringRenderer source={props.requiredText} />
                     </label>
                  ) : null}
               </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6 z-10">
               <div className="w-full px-3 z-10">
                  <div className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                     <MdStringRenderer source={props.subject} />
                  </div>
                  <input
                     className="appearance-none block w-full bg-formbg text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                     id="grid-subject"
                     type="text"
                     name="subject"
                     value={formState.subject}
                     onChange={handleChange}
                  ></input>
               </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
               <div className="w-full px-3">
                  <div className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                     <MdStringRenderer source={props.message} />
                  </div>
                  <textarea
                     className="no-resize appearance-none block w-full bg-formbg text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                     id="message"
                     name="text"
                     value={formState.text}
                     onChange={handleChange}
                  ></textarea>
               </div>
            </div>
            <div className="flex justify-center">
               <div>
                  <button
                     id="submit"
                     className="shadow bg-primary hover:bg-secondary focus:shadow-outline focus:outline-none text-white hover:text-black font-bold py-2 px-4 rounded"
                     type="submit"
                     disabled={formState.submitting}
                  >
                     <MdStringRenderer source={props.btnText} />
                  </button>
               </div>
            </div>
            <div className="ajax-response text-center mt-5" id="contact-response">
               <div>
                  {formState.valid !== null ? (
                     formState.valid === true ? (
                        <MdStringRenderer source={props.succes} />
                     ) : (
                        <MdStringRenderer source={props.error} />
                     )
                  ) : null}
               </div>
            </div>
         </form>
      </div>
   );
};
