import { LinkMatix, MdStringRenderer } from '@matix/utils';
import React from 'react';
import { Breadcrumb } from '../breacrumb';
export interface BreadcrumbProps extends React.HTMLAttributes<HTMLElement> {
   items: Breadcrumb[];
}

export const BreadcrumbMtx = (props: BreadcrumbProps) => {
   const { items } = props;

   return (
      <nav aria-label="pagination">
         <ol className="pl-2 text-sm flex flex-wrap items-baseline justify-start lg:text-2xl py-5 lg:pl-5 border-double border-b-4 border-black border-opacity-20 bg-white">
            {items.map((item, idx) => (
               <li className="hover:text-gray-500 whitespace-nowrap">
                  <div className="flex flex-row">
                     <MdStringRenderer source={item} />
                     {idx < items.length - 1 ? <span>&nbsp; {` - `}&nbsp;</span> : null}
                  </div>
               </li>
            ))}
         </ol>
      </nav>
   );
};
