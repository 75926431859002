import React, { useContext } from 'react';
import { ProductSummaryCard } from './product-summary-card';
import { LinkMatix } from '@matix/utils';
import { Breadcrumb, PageInfo, StoreContext } from '@briqueconcept/ui';
import { BreadcrumbMtx } from '../breacrumb';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
   title: string;
   breadcrumbs: Breadcrumb[];
   btnDetailText: string;
   grouping: any;
   pageInfo: PageInfo;
   categories: any[];
   seoData: any;
}
export const ProductCategories = (props: Props) => {
   const { grouping } = props;
   const { getPageVendorUrl, getPageCategoryUrl } = useContext(StoreContext);
   const sorted = [...grouping.categories];
   sorted.sort((a, b) => (a.fieldValue < b.fieldValue ? -1 : 1));

   return (
      <div className="py-4 lg:px-20 lg:py-8 mx-auto">
         <div className="flex flex-col justify-start sm:flex-row">
            <div className="px-4 hidden xl:block xl:text-left flex-shrink">
               <div className="sticky top-10">
                  <h2 className=" font-semibold text-2xl text-center xl:text-left whitespace-nowrap">Catégories</h2>
                  {sorted.map((group) => (
                     <div key={group.fieldValue} className="italic">
                        <LinkMatix className="hover:bg-yellow-200" to={getPageCategoryUrl(group.fieldValue)}>
                           {group.fieldValue} ({group.totalCount})
                        </LinkMatix>
                     </div>
                  ))}
                  <h2 className=" font-semibold text-2xl mt-10 text-center xl:text-left whitespace-nowrap">Fabriquants</h2>
                  {grouping.providers.map((group) => (
                     <div className="italic">
                        <LinkMatix className="hover:bg-yellow-200" to={getPageVendorUrl(group.fieldValue)}>
                           {group.fieldValue} ({group.totalCount})
                        </LinkMatix>
                     </div>
                  ))}
               </div>
            </div>

            <div className="flex-grow">
               <div className="sticky top-0 z-40 mb-10">
                  <BreadcrumbMtx items={props.seoData.breadcrumbs}></BreadcrumbMtx>
               </div>

               <div className="grid md:grid-cols-2 2xl:grid-cols-3 gap-12">
                  {grouping.categories.map((group, idx: number) => {
                     const child: any = props.categories.find((item: any) => item.title === group.fieldValue);
                     if (!child) return null;

                     return (
                        <div key={idx}>
                           <ProductSummaryCard
                              hideHeart={true}
                              name=""
                              imgMain={{ url: child.img }}
                              productLink={getPageCategoryUrl(group.fieldValue)}
                              category={child.title}
                              btnDetailText={props.btnDetailText}
                           ></ProductSummaryCard>
                        </div>
                     );
                  })}
               </div>
            </div>
         </div>
      </div>
   );
};
