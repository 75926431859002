import React from 'react';
import { ImageProps, Image, MatixBackgroundImage } from '@matix/ui';
import { MdStringRenderer } from '@matix/utils';

interface Props {
   img: ImageProps;
   title: string;
   subtitle: string;
   description: string;
   teaser: string;
   contactForm: React.ReactElement;
}
export const HiringSection = (props: Props) => {
   return (
      <div className="w-full xl:h-screen">
         <div className="absolute w-full h-full -z-1">
            <div className="bg-black absolute w-full h-full opacity-60"></div>
            <MatixBackgroundImage {...props.img} />
         </div>
         <div className="w-full px-2 py-4 lg:pt-10">
            <div className="flex flex-wrap-reverse xl:flex-wrap">
               <div className="mx-auto xl:w-1/3 xl:mx-0 xl:pl-4">{props.contactForm}</div>
               <div className="mx-auto xl:w-2/3 text-white text-xl pt-6 mt-6">
                  <div className="flex flex-col text-center">
                     <div className="my-1 lg:my-4 text-4xl font-semibold">
                        <MdStringRenderer source={props.title} />
                        <div className="w-1/2 mx-auto">
                           <MdStringRenderer source={'---'} />
                        </div>
                     </div>
                     <div className="my-4">
                        <MdStringRenderer source={props.subtitle} />
                     </div>
                     <div className="my-4">
                        <MdStringRenderer source={props.description} />
                     </div>
                     <div className="my-4">
                        <MdStringRenderer source={props.teaser} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
