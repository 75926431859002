import { isSSR } from '../is-ssr';

/**
 * Receives as props the list of all queries matching a language. The mdx with
 * proper language is returned. In case we are SSR, we take the first one as it is not important.
 */
interface Props {
   defaultWebsiteLocale: string;
   mdxEdges: any;
}
export const GetTranslated = (props: Props) => {
   const { mdxEdges, defaultWebsiteLocale } = props;
   let matched = null;
   if (!isSSR()) {
      // Find the proper traduction based off of current url and mdx locale prop
      matched = mdxEdges.find(({ node }) => {
         return window.location.pathname.includes(`/${node.frontmatter.locale}/`);
      });
   }
   // Take default language if can't find in url. For instance, if default is fr, fr won't necessary be in the url.
   if (!matched) {
      matched = mdxEdges.find(({ node }) => node.frontmatter.locale === defaultWebsiteLocale);
   }
   if (!matched) matched = mdxEdges[0]?.node;
   if (!matched) throw Error('There is no frontmatter for current language');
   return matched;
};
