import React, { useContext } from 'react';
import { MdStringRenderer } from '@matix/utils';
import { FavoriteItem, StoreContext, StoreContextProps } from '../contexts/store-context';
import { FavoriteSummaryCard } from './favorite-summary-card';

export const FavoriteCards = (props: any) => {
   const { store } = useContext(StoreContext) as StoreContextProps;

   return (
      <div className="font-semibold">
         <div className="w-full text-center text-xl xl:text-4xl mb-10">
            {store.favoriteProducts && store.favoriteProducts.length > 0 ? (
               <MdStringRenderer source={props.title} />
            ) : (
               <MdStringRenderer source={props.default} />
            )}
         </div>

         <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 xl:mx-32">
            {store.favoriteProducts &&
               store.favoriteProducts.map((item: FavoriteItem, idx: number) => (
                  <div key={idx} className="h-full">
                     <FavoriteSummaryCard {...item} btnDetailText={props.btnDetailText} />
                  </div>
               ))}
         </div>
      </div>
   );
};
