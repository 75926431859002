import React from 'react';
import { MdStringRenderer } from '@matix/utils';

export interface HeadlineProps {
   title: string;
}

export const Headline = (props: HeadlineProps) => {
   return (
      <div className="text-2xl my-10 z-50 text-center text-indigo-600 underline">
         <MdStringRenderer source={props.title} />
      </div>
   );
};
