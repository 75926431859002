import React from 'react';
import { MdStringRenderer } from '@matix/utils';
import { LogoSvg } from '../svg';
import { Facebook } from '@matix/ui';

export interface Props {
   company: string;
   title: string;
   facebook: string;
   vendors: string;
   vendorsList: string[];
   categories: string;
   categoriesList: string[];
   grouping: any;
   home: string;
   contact: string;
   enterprise: string;
   jobs: string;
   developBy: string;
   copywrite: string;
}
export const Footer = (props: Props) => {
   const topCategories = props.grouping.categories.sort((x, y) => {
      return y.totalCount - x.totalCount;
   });

   return (
      <div className="text-center mx-auto lg:mx-0 lg:ml-20">
         <div className="flex flex-col  lg:items-start mt-6 mx-auto lg:flex-row">
            <div className="flex flex-col items-center lg:items-start lg:text-left lg:justify-center flex-1">
               <LogoSvg />
               <div className="my-5">
                  <MdStringRenderer source={props.title} />
               </div>
               <div className="flex">
                  <MdStringRenderer source={props.facebook} />
                  <Facebook className="w-6 ml-3" />
               </div>
            </div>
            <div className="flex flex-col my-5 lg:my-0 text-2xl flex-1">
               <MdStringRenderer source={props.vendors} />
               <div className="text-base ">
                  {props.vendorsList &&
                     props.vendorsList.map((gr: string, idx: number) => (
                        <div key={idx}>
                           <MdStringRenderer source={gr} />
                        </div>
                     ))}
               </div>
            </div>
            <div className="flex flex-col my-5 lg:my-0 text-2xl  flex-1">
               <MdStringRenderer source={props.categories} />
               <div className="text-base ">
                  {props.categoriesList &&
                     props.categoriesList.map((gr: string, idx: number) => (
                        <div key={idx}>
                           <MdStringRenderer source={gr} />
                        </div>
                     ))}
               </div>
            </div>
            <div className="flex flex-col my-5 lg:my-0 text-2xl flex-1">
               <div className=" whitespace-nowrap">
                  <MdStringRenderer source={props.company} />
                  <div className="text-base">
                     <MdStringRenderer source={props.home} />
                     <MdStringRenderer source={props.contact} />
                     <MdStringRenderer source={props.enterprise} />
                     <MdStringRenderer source={props.jobs} />
                  </div>
               </div>
            </div>
         </div>
         <div className="flex justify-center">
            <div className="flex flex-col items-center space-y-2 my-10">
               <div className="flex">
                  &copy;{`  ${new Date().getFullYear()}-`}
                  <MdStringRenderer source={props.copywrite} />
               </div>
               <MdStringRenderer source={props.developBy} />
            </div>
         </div>
      </div>
   );
};
