import { Heart } from '@matix/ui';
import { MdStringRenderer } from '@matix/utils';
import React from 'react';
import { LogoSvg, MenuHamburgerSvg } from '../svg';
import { HeaderMobile } from './header-mobile';
// https://www.tailwindtoolbox.com/components/megamenu
interface Props {
   company: string;
   favoriteLink: string;
   title: string;
   link?: string;
   questionText: string;
   callUsText: string;
   children: React.ReactNode;
}
export const Header = (props: Props) => {
   return (
      <div className="bg-white">
         <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-4 md:justify-start md:space-x-10">
               <div className="flex justify-start lg:w-0 lg:flex-1">
                  <a href="/">
                     <span className="sr-only">
                        <MdStringRenderer source={props.company} />
                     </span>
                     <LogoSvg className="w-40" />
                  </a>
               </div>
               <div className="flex items-center justify-end -mr-2 -my-2">
                  <button
                     type="button"
                     className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:hidden"
                  >
                     <span className="sr-only">Open menu</span>
                     <MenuHamburgerSvg />
                  </button>
               </div>

               <nav className="hidden md:flex space-x-10">{props.children}</nav>
               <div className="hidden md:flex items-center justify-end">
                  <MdStringRenderer source={props.favoriteLink} className="flex h-6">
                     <Heart className="ml-4 w-6 h-6 fill-current text-red-600" />
                  </MdStringRenderer>
               </div>
            </div>
         </div>

         <HeaderMobile {...props}>{props.children}</HeaderMobile>
      </div>
   );
};
