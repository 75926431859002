import React from 'react';
import { MdStringRenderer } from '@matix/utils';

export const EnterpriseSection = (props: any) => {
   return (
      <div className="w-full py-10 px-5">
         <div className="flex flex-col lg:w-1/2 space-y-10 mx-auto">
            <div className="text-2xl lg:text-4xl text-center font-semibold">
               <MdStringRenderer source={props.title} />
            </div>
            {props.children}
         </div>
      </div>
   );
};
