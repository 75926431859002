import { Image } from '@matix/ui';
import { LinkMatix } from '@matix/utils';
import React, { useContext } from 'react';
import { FavoriteHearth } from '../product/favorite-hearth';
import { FavoriteItem, StoreContext } from '../contexts/store-context';
import { MdStringRenderer } from '@matix/utils';

const urlToUniqueName = (url) => {
   return url.split('').reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
   // TODO: I wanted to use public url as name but it needs to fit some requirements to be store in azure..
   // i.e. no /, nothing after extension if extension is provided i.e. .jpg%456456
   // return url
   //    .replace(/https:\/\//, '')
   //    .replace(/http:\/\//, '')
   //    .replace(/\//g, '-');
};

export const FavoriteSummaryCard = (props: FavoriteItem) => {
   const { getLogo } = useContext(StoreContext);
   const imgUrl = 'https://briqueimages.blob.core.windows.net/images/' + urlToUniqueName(props.image.url);
   return (
      <div className="grid grid-cols-1 bg-gray-100 shadow-xl">
         <div className="col-start-1 row-start-1 col-span-1 z-10">
            <div className="relative mx-auto">
               <div className="absolute z-10 bg-white opacity-80 py-2 px-3 mt-3 ml-3">
                  {getLogo({ provider: props.provider, className: 'w-40 h-8' })}
               </div>
            </div>
         </div>

         <div className="relative z-10 col-start-1 row-start-1 px-4 pb-4 mt-40 pt-40 border-gray-200 bg-gradient-to-t from-black">
            {props.category ? (
               <div className="text-sm font-medium text-white">
                  <MdStringRenderer source={props.category} />
               </div>
            ) : null}
            <div className="text-xl font-semibold text-white">
               <MdStringRenderer source={props.productName} />
            </div>
         </div>

         <div className="col-start-1 row-start-1">
            <div className="w-full h-full">
               <div className="relative h-full">
                  <div className="absolute z-10 right-5 -mt-8">
                     <FavoriteHearth
                        className="w-16 h-16"
                        favorite={{
                           url: props.url,
                           image: props.image,
                           productName: props.productName,
                           provider: props.provider,
                           category: props.category,
                        }}
                     ></FavoriteHearth>
                  </div>
                  <div className="absolute w-full h-full">
                     <Image url={imgUrl} className="inset-0 h-full object-cover bg-gray-100" />
                  </div>
               </div>
            </div>
         </div>

         <div className="col-start-1 row-start-4 py-3 px-auto text-center bg-gray-100">
            <LinkMatix to={props.url}>
               <button
                  type="button"
                  className="shadow bg-primary focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded hover:bg-secondary hover:text-black"
               >
                  <MdStringRenderer source={props.btnDetailText} />
               </button>
            </LinkMatix>
         </div>
      </div>
   );
};
