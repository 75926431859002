import React from 'react';
import { MdStringRenderer } from '@matix/utils';
import { Phone, Envelope } from '@matix/ui';

interface Props {
   title: string;
   description: string;
   address: string;
   tel: string;
   mail: string;
   lastly: string;
}

export const ContactInfo = (props: Props) => {
   return (
      <div className="relative leading-5">
         <div className="flex flex-col font-semibold">
            <div className="text-4xl text-center leading-7 my-5">
               <MdStringRenderer source={props.title} />
            </div>
            <div className="text-xl mb-3 leading-6">
               <MdStringRenderer source={props.description} />
            </div>
            <div className="text-base flex flex-col ml-5 space-y-3">
               <MdStringRenderer source={props.address} />
               <a className="flex flex-row space-x-2 max-h-8" href={`tel:" + ${props.tel}`} target="_blank" rel="noopener noreferrer">
                  <Phone className="w-4 h-6" />
                  <span>
                     <MdStringRenderer source={props.tel} />
                  </span>
               </a>
               <a className="flex flex-row space-x-2 max-h-8" href={`mailto:${props.mail}`} target="_blank" rel="noopener noreferrer">
                  <Envelope className="w-4 h-6" />
                  <MdStringRenderer source={props.mail} />
               </a>
            </div>
            <div className="text-xl mt-5">
               <MdStringRenderer source={props.lastly} />
            </div>
         </div>
      </div>
   );
};
