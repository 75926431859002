// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-products-by-category-list-tsx": () => import("./../../../src/templates/products-by-category-list.tsx" /* webpackChunkName: "component---src-templates-products-by-category-list-tsx" */),
  "component---src-templates-products-by-vendor-list-tsx": () => import("./../../../src/templates/products-by-vendor-list.tsx" /* webpackChunkName: "component---src-templates-products-by-vendor-list-tsx" */)
}

