import { LinkMatix } from '@matix/utils';
import React, { useContext } from 'react';
import { StoreContext } from '../contexts/store-context';
import { Product, PageInfo } from '../data';
import { Pager } from '../pager';
import { ProductSummaryCard } from './product-summary-card';
import { BreadcrumbMtx } from '../breacrumb/breadcrumb';
export interface ProductSectionProps extends React.HTMLAttributes<HTMLElement> {
   products: { frontmatter: Product }[];
   grouping: any;
   pageInfo: PageInfo;
   uri: string;
   btnDetailText: string;
   seoData: any;
}
// Receives product data, present filters, data & pager
export const ProductSection = (props: ProductSectionProps) => {
   const { products, pageInfo, grouping } = props;
   const { getLogo, getPageVendorUrl, getPageCategoryUrl } = useContext(StoreContext);
   const getSecondyUrls = (product: Product) => {
      return product.variant?.map((v) => ({ url: v.imgLink }));
   };
   const sorted = [...grouping.categories];
   sorted.sort((a, b) => (a.fieldValue < b.fieldValue ? -1 : 1));

   return (
      <div className="py-4 lg:px-20 lg:py-8 mx-auto">
         <div className="flex flex-col justify-start sm:flex-row">
            <div className="px-4 hidden xl:block xl:text-left flex-shrink">
               <div className="sticky top-10">
                  <h2 className=" font-semibold text-2xl text-center xl:text-left whitespace-nowrap">Catégories</h2>
                  {sorted.map((group) => (
                     <div key={group.fieldValue} className="italic">
                        <LinkMatix className="hover:bg-yellow-200" to={getPageCategoryUrl(group.fieldValue)}>
                           {group.fieldValue} ({group.totalCount})
                        </LinkMatix>
                     </div>
                  ))}
                  <h2 className=" font-semibold text-2xl mt-10 text-center xl:text-left whitespace-nowrap">Fabriquants</h2>
                  {grouping.providers.map((group) => (
                     <div className="italic">
                        <LinkMatix className="hover:bg-yellow-200" to={getPageVendorUrl(group.fieldValue)}>
                           {group.fieldValue} ({group.totalCount})
                        </LinkMatix>
                     </div>
                  ))}
               </div>
            </div>
            <div className="flex-grow">
               <div className="sticky top-0 z-40 mb-10">
                  {props.seoData ? <BreadcrumbMtx items={props.seoData.breadcrumbs}></BreadcrumbMtx> : null}
                  {pageInfo.pageCount > 1 ? <Pager pageInfo={pageInfo} /> : null}
               </div>

               <div className="grid md:grid-cols-2 2xl:grid-cols-3 gap-12">
                  {products.map((p) => (
                     <div className="border-2 border-opacity-10  border-black ">
                        <ProductSummaryCard
                           productLink={p.frontmatter.slug}
                           slug={p.frontmatter.slug}
                           name={p.frontmatter.name}
                           category={p.frontmatter.category}
                           description={p.frontmatter.description}
                           provider={p.frontmatter.provider}
                           imgMain={{ url: p.frontmatter.imgLink }}
                           imgSecondaries={getSecondyUrls(p.frontmatter)}
                           logo={getLogo({ provider: p.frontmatter.provider, className: 'w-40 h-8' })}
                           btnDetailText={props.btnDetailText}
                        />
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
};
