import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Footer } from '@briqueconcept/ui';
export const _frontmatter = {
  "type": "footer",
  "locale": "fr",
  "localImages": [],
  "eagerImages": [],
  "lazyImages": []
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <Footer title="Le plus grand distributeur de produits d'aménagement paysager de l'est du Québec." facebook="Suivez-nous sur [Facebook](https://www.facebook.com/briqueconcept/)" vendors="### Vendeurs" vendorsList={['[Permacon](https://permacon.ca)', '[Techo-bloc](https://www.techo-bloc.com/)', '[Rinox](https://www.rinox.ca/)', '[Beonstone](https://www.beonstone.com/)']} categories="### Produits" categoriesList={['[Dalle](/produits/categorie/dalle/page/1)', '[Pavé](/produits/categorie/pave/page/1)', '[Maçonnerie](/produits/categorie/maconnerie/page/1)', '[Pierre vissé](/produits/categorie/pierre-visse/page/1)']} company="### Compagnie" home="[Accueil](/)" contact="[Contactez-nous](/amenagement-paysager-centre-distribution-contactez-nous)" enterprise="[Entreprise](/amenagement-paysager-centre-distribution-entreprise)" jobs="[Emplois](/emplois-centre-distribution-amenagement-paysager)" copywrite="Brique Concept Inc." developBy="Site développé par [Matix](https://matix.ca)" {...props}></Footer>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      