import { HiringSection, Form } from '@briqueconcept/ui';
import FooterMdx from "..\\..\\..\\..\\src\\markdown\\fr\\footer.mdx";
import HeaderMdx from "..\\..\\..\\..\\src\\markdown\\fr\\header.mdx";
import * as React from 'react';
export default {
  HiringSection,
  Form,
  FooterMdx,
  HeaderMdx,
  React
};