import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Header, HeaderCategory } from '@briqueconcept/ui';
export const _frontmatter = {
  "type": "header",
  "locale": "fr",
  "localImages": ["sharePicture2"],
  "eagerImages": [],
  "lazyImages": []
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <Header company="Brique Concept" questionText="Des questions ?" callUsText="Téléphonez-nous !" favoriteLink="[Vos favoris](/produits/favoris)">
    <HeaderCategory link="[Accueil](/)" />
    <HeaderCategory link="Produits" mainTitleText="Brique concept est le plus grand distributeur de l'est du Québec !" subTitleText="Venez nous rencontrer pour vos projets d'aménagement et de maçonnerie !" mainHeaderImage={{
    url: 'sharePicture2',
    loading: 'lazy'
  }}>
      <HeaderCategory slug="/produits/categorie/" link="[Catégories](/produits/categorie)" group={props.grouping.categories} showAllText="Voir toutes les catégories" {...props} />
      <HeaderCategory link="Fabriquants" slug="/produits/vendeur/" group={props.grouping.providers} showAllText="Voir tous les vendeurs" {...props} />
    </HeaderCategory>
    <HeaderCategory link="[Entreprise](/amenagement-paysager-centre-distribution-entreprise)" />
    <HeaderCategory link="[Nous engageons](/emplois-centre-distribution-amenagement-paysager)" />
    <HeaderCategory link="[Contactez-nous](/amenagement-paysager-centre-distribution-contactez-nous)" />
  </Header>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      