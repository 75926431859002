import React from 'react';
import Helmet from 'react-helmet';
import Facebook from './facebook';

export const SEO = (props: any) => {
   const { siteMeta, pagesMeta, breadcrumbs } = props;
   const isSSR = typeof window === 'undefined';
   const currentPagePath = !isSSR && window.location ? window.location.pathname : '/';
   const currentSiteUrl = siteMeta.siteUrl + currentPagePath;

   const webSiteLogoUrl = `${siteMeta.siteUrl}/static/${siteMeta.logo}`;
   const seoShareImageSrc = `${siteMeta.siteUrl}/static/${siteMeta.seoImageShare}`;

   const currentLang = pagesMeta.locale;

   const breadcrumb = {
      '@type': 'BreadcrumbList',
      name: siteMeta.companyName,
      numberOfItems: breadcrumbs.length,
      itemListElement: breadcrumbs,
   };

   const corporateContact = {
      '@type': 'Organization',
      url: siteMeta.siteUrl,
      address: siteMeta.postalAdress,
      email: siteMeta.email,
      logo: webSiteLogoUrl,
      legalName: siteMeta.companyName,
      contactPoint: [
         {
            '@type': 'ContactPoint',
            telephone: siteMeta.telephone,
            email: siteMeta.email,
            contactType: 'customer service',
            areaServed: 'CA',
            availableLanguage: ['French', 'English'], // todo: populate from list of locales
         },
      ],
   };

   const openingHoursSpecification = siteMeta.openingHoursSpecification.map((item: any) => {
      const hoursSpec = {
         '@type': 'OpeningHoursSpecification',
         dayOfWeek: item.info.dayOfWeek,
         opens: item.info.opens,
         closes: item.info.closes,
      };
      return hoursSpec;
   });

   const localBusinessInfo = {
      '@type': 'Store',
      '@id': siteMeta.siteUrl,
      url: currentSiteUrl,
      name: siteMeta.companyName,
      telephone: siteMeta.telephone,
      address: siteMeta.postalAdress,
      image: webSiteLogoUrl,
      openingHoursSpecification,
   };

   const groupRichContentsGraph = {
      '@context': 'https://schema.org',
      '@graph': [breadcrumb, corporateContact, localBusinessInfo],
   };

   const fbLocal = currentLang + '_CA';
   const image32 = `${siteMeta.siteUrl}/static/favicon-32x32`;
   const image16 = `${siteMeta.siteUrl}/static/favicon-16x16`;
   const image = `${siteMeta.siteUrl}/static/favicon`;

   return (
      <>
         <Helmet title={pagesMeta.title}>
            <html lang={currentLang} />
            <meta name="robots" content="Index, Follow" />
            <meta name="description" content={pagesMeta.description} />
            <meta name="image" content={seoShareImageSrc} />
            <meta name="title" content={pagesMeta.title} />
            <meta name="keywords" content={pagesMeta.keywords} />
            <link rel="icon" type="image/png" sizes="144x144" href={image} />
            <link rel="icon" type="image/png" sizes="32x32" href={image32} />
            <link rel="icon" type="image/png" sizes="16x16" href={image16} />
            <script type="application/ld+json">{JSON.stringify(groupRichContentsGraph)}</script>
         </Helmet>
         <Facebook
            desc={pagesMeta.description}
            image={seoShareImageSrc}
            imageWidth={siteMeta.imageShareWidth}
            imageHeight={siteMeta.imageShareHeight}
            title={pagesMeta.title}
            type={'website'}
            url={currentSiteUrl}
            locale={fbLocal}
            name={siteMeta.companyName}
         />
      </>
   );
};
