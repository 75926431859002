import { ImageProps, Image } from '@matix/ui';
import { MdStringRenderer } from '@matix/utils';
import React from 'react';

export const HeroWithImage = ({ mainTitle, secondaryTitle, subtitle, btnMainTitle, btnSecondaryTitle, img, companyName }: Props) => {
   return (
      <div className="relative bg-white overflow-hidden">
         <div className="max-w-7xl mx-auto">
            <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
               <svg
                  className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                  fill="currentColor"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                  aria-hidden="true"
               >
                  <polygon points="50,0 100,0 50,100 0,100" />
               </svg>

               <div className="relative pt-6 px-4 sm:px-6 lg:px-8"></div>

               <main className="mt-6 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                  <div className="sm:text-center lg:text-left">
                     <div className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                        <div className="block xl:inline">
                           <MdStringRenderer source={mainTitle} />
                        </div>
                        <span className="block text-primary xl:inline">
                           <MdStringRenderer source={companyName} />
                        </span>
                     </div>
                     <div className="mt-3 text-base font-semibold text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        <MdStringRenderer source={secondaryTitle} />
                     </div>

                     <div className="mt-3  text-base text-primary sm:mt-7 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-7 md:text-xl lg:mx-0">
                        <MdStringRenderer source={subtitle} />
                     </div>
                     <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                        <div className="rounded-md shadow">
                           <div className="w-full flex items-center justify-center border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-secondary hover:text-black md:text-lg">
                              <MdStringRenderer source={btnMainTitle} className="px-8 py-3 md:py-4 md:px-10" />
                           </div>
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                           <div className="w-full flex items-center justify-center border border-transparent text-base font-medium rounded-md text-black bg-secondary hover:bg-primary hover:text-white md:text-lg">
                              <MdStringRenderer source={btnSecondaryTitle} className="px-8 py-3 md:py-4 md:px-10" />
                           </div>
                        </div>
                     </div>
                  </div>
               </main>
            </div>
         </div>
         <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <Image className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" {...img} />
         </div>
      </div>
   );
};

interface Props {
   mainTitle: string;
   companyName: string;
   subtitle: string;
   secondaryTitle: string;
   btnMainTitle: string;
   btnMainUrl: string;
   btnSecondaryTitle: string;
   btnSecondaryUrl: string;
   img: ImageProps;
}
