import React from 'react';
import { PageInfo } from '../data';
import { LinkMatix } from '@matix/utils';
import { classnames } from 'tailwindcss-classnames';

import c from 'classnames';
export interface PagerProps extends React.HTMLAttributes<HTMLElement> {
   pageInfo: PageInfo;
}

const borderHover = (disabled: boolean) =>
   classnames(
      'py-1',
      'px-2',
      'border-indigo-500',
      'border-b-2',
      'border-opacity-0',
      'md:py-3',
      'md:px-4',
      !disabled ? 'hover:border-opacity-100' : null,
      disabled ? 'font-extralight' : null,
      disabled ? 'hover:bg-gray-50' : 'hover:bg-gray-200'
   );
export const Pager = (props: PagerProps) => {
   const { pageInfo } = props;
   const pages = Array(pageInfo.pageCount).fill(0);
   return (
      <nav aria-label="pagination">
         <div className="flex items-baseline justify-around border-t border-gray-100 z-40 bg-white">
            {pageInfo.hasPreviousPage ? (
               <LinkMatix to={`../${+pageInfo.currentPage - 1}`} className={c(borderHover(false), 'flex-shrink text-right')}>
                  {pageInfo.previousText}
               </LinkMatix>
            ) : (
               <span className={c(borderHover(true), 'flex-shrink text-right')}>{pageInfo.previousText}</span>
            )}
            <div className="flex-grow"></div>
            {pages.map((p, idx) => (
               <LinkMatix to={`../${idx + 1}`} className={c(borderHover(false), pageInfo.currentPage === idx + 1 ? 'font-extrabold' : '')}>
                  {idx + 1}
               </LinkMatix>
            ))}
            <div className="flex-grow"></div>
            {pageInfo.hasNextPage ? (
               <LinkMatix to={`../${+pageInfo.currentPage + 1}`} className={c(borderHover(false), 'flex-shrink text-right')}>
                  {pageInfo.nextText}
               </LinkMatix>
            ) : (
               <span className={c(borderHover(true), 'flex-shrink text-right font-extralight')}>{pageInfo.nextText}</span>
            )}
         </div>
      </nav>
   );
};
