import GatsbyLink from 'gatsby-link';
import React from 'react';

export const LinkMatix = (props) => {
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         {props.to && props.to.startsWith('http') ? (
            <a className={props.className} href={props.to} target="_blank" rel="noopener noreferrer">
               {props.text ? props.text : props.children}
            </a>
         ) : (
            <GatsbyLink {...props} to={props.to}>
               <span>{props.text ? props.text : props.children}</span>
            </GatsbyLink>
         )}
      </>
   );
};
