import React, { useContext } from 'react';
import GatsbyImage, { FluidObject, GatsbyImageOptionalProps, FixedObject } from 'gatsby-image';
import { LocalImagesContext } from '@matix/utils';
export interface ImageProps extends ImageFluidProps, ImageFixedProps {
   url?: string;
}

interface ImageFluidProps extends GatsbyImageOptionalProps {
   fluid?: FluidObject | FluidObject[];
}

interface ImageFixedProps extends GatsbyImageOptionalProps {
   fixed?: FixedObject | FixedObject[];
}

export const MatixBackgroundImage = (props: ImageProps) => {
   const localImagesCtx = useContext(LocalImagesContext);
   const nullImg = localImagesCtx.images.filter((img) => !img);
   const { url } = props;
   const getImageFromContext = (key) => {
      let img: any;

      if (key.startsWith('http')) {
         img = localImagesCtx.images.find((localImage) => localImage?.url === key);
      } else {
         img = localImagesCtx.images.find((localImage) => localImage?.name === key);
      }
      if (!img) {
         console.debug(`The image with url ${key} is not processed and optimized by Gatsby. Consider adding url to local build.`);
         return null;
      }
      return img;
   };
   const imgFromContext = url && getImageFromContext(url);
   const isFromCtx = imgFromContext === null ? false : true;
   if (isFromCtx) {
      return (
         <GatsbyImage
            {...props}
            fluid={imgFromContext.childImageSharp.fluid}
            fixed={imgFromContext.childImageSharp.fixed}
            style={{ height: '100%', zIndex: -10 }}
         ></GatsbyImage>
      );
   } else {
      return (
         <div>
            {url ? (
               <img alt={props.alt} src={url} className={props.className.toString()} style={{ zIndex: -10 }}></img>
            ) : (
               <GatsbyImage {...props} fluid={props.fluid} fixed={props.fixed}></GatsbyImage>
            )}
         </div>
      );
   }
};
