import { HearthAddSvg, HearthRemoveSvg } from '@matix/ui';
import c from 'classnames';
import React, { useContext } from 'react';
import { FavoriteItem, StoreContext, StoreContextProps } from '../contexts/store-context';
export interface FavoriteHearthProps extends React.HTMLAttributes<HTMLDivElement> {
   favorite: Partial<FavoriteItem>;
}
export const FavoriteHearth = (props: FavoriteHearthProps) => {
   const { favorite } = props;
   const { addRemoveFavoriteItem, store } = useContext(StoreContext) as StoreContextProps;
   const isFav = (url: string) => {
      return store.favoriteProducts.findIndex((p) => p.url === url) > -1;
   };
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         {isFav(favorite.url) ? (
            <HearthRemoveSvg
               onClick={() => addRemoveFavoriteItem(favorite)}
               className={c('text-red-700 hover:text-red-400', props.className)}
            ></HearthRemoveSvg>
         ) : (
            <HearthAddSvg
               onClick={() => addRemoveFavoriteItem(favorite)}
               className={c('text-red-400 animate-pulse  hover:text-red-700', props.className)}
            ></HearthAddSvg>
         )}
      </>
   );
};
