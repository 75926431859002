import { FabClassKey } from '@material-ui/core/Fab';
import { ImageProps } from '@matix/ui';
import React, { useEffect, useState } from 'react';
import { LogoSvg, PermaconSvg, RinoxSvg, TechoSvg, BeonstoneSvg } from '../svg';
import produce from 'immer';
import { isSSR } from '@matix/utils';
export interface StoreContextProps {
   getLogo(logoProps: React.HTMLAttributes<SVGElement> & { provider: string }): React.ReactNode;
   slugify(string): string;
   getPageVendorUrl(string): string;
   getPageCategoryUrl(string): string;
   addRemoveFavoriteItem(item: Partial<FavoriteItem>): void;
   store: Store;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const StoreContext = React.createContext<StoreContextProps>({
   getLogo: () => null,
   slugify: () => '',
   getPageVendorUrl: () => '',
   getPageCategoryUrl: () => '',
   addRemoveFavoriteItem: () => '',
   store: { favoriteProducts: [] },
});

export const StoreProvider = (props) => {
   const storageKey = 'BRIQUE_CONCEPT_STORE';
   const initialState: Store = {
      favoriteProducts: [],
   };

   const [store, updateStore] = useState(initialState);

   useEffect(() => {
      if (isSSR()) return;
      const state = localStorage.getItem(storageKey);
      if (state) updateStore(JSON.parse(state));
   }, []);

   useEffect(() => {
      if (isSSR()) return;
      localStorage.setItem(storageKey, JSON.stringify(store));
   }, [store]);
   return (
      <StoreContext.Provider
         value={{
            store,
            getLogo: (logoProps) => {
               const provider = logoProps.provider;
               if (provider === 'Permacon') return <PermaconSvg {...logoProps} />;
               if (provider === 'Rinox') return <RinoxSvg {...logoProps} />;
               if (provider === 'Techo-Bloc') return <TechoSvg {...logoProps} />;
               if (provider === 'Beonstone') return <BeonstoneSvg {...logoProps} />;
               return LogoSvg; // default brique logo otherwise
            },
            slugify: slugify,
            getPageVendorUrl: (vendor) => {
               // TODO: set store locale to be able to retrieve  proper path,
               // include PROVIDER_PATH from constants during page build as well
               return `/produits/vendeur/${slugify(vendor)}/page/1`;
            },
            getPageCategoryUrl: (category) => {
               // TODO: set store locale to be able to retrieve  proper path,
               // include CATEGORY_PATH from constants during page build as well
               return `/produits/categorie/${slugify(category)}/page/1`;
            },
            addRemoveFavoriteItem: (item: FavoriteItem) => {
               updateStore(
                  produce((draft: Store) => {
                     const idx = draft.favoriteProducts.findIndex((p) => p.url === item.url);
                     idx !== -1 ? draft.favoriteProducts.splice(idx, 1) : draft.favoriteProducts.push(item);
                  })
               );
            },
         }}
      >
         {props.children}
      </StoreContext.Provider>
   );
};

const slugify = (value) => {
   return value.replace(/é/g, 'e').replace(/è/g, 'e').replace(/à/g, 'a').replace(/ç/g, 'c').replace(' ', '-').toLowerCase();
};

export interface Store {
   favoriteProducts: FavoriteItem[];
}
export interface FavoriteItem {
   url: string;
   btnDetailText: string;
   image: ImageProps;
   description: string;
   productName: string;
   category: string;
   provider: string;
}
