import { FavoriteSection } from '@briqueconcept/ui';
import { Image } from '@matix/ui';
import FooterMdx from "..\\..\\..\\..\\src\\markdown\\fr\\footer.mdx";
import HeaderMdx from "..\\..\\..\\..\\src\\markdown\\fr\\header.mdx";
import * as React from 'react';
export default {
  FavoriteSection,
  Image,
  FooterMdx,
  HeaderMdx,
  React
};