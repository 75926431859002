import React from 'react';
import { MdStringRenderer } from '@matix/utils';

interface Props {
   title: string;
   monday: string;
   tuesday: string;
   wednesday: string;
   thursday: string;
   friday: string;
   saterday: string;
   sunday: string;
   allways: string;
   businessWeek: string;
   weekend: string;
}

export const OpenHours = (props: Props) => {
   return (
      <div className="leading-5 mt-2">
         <div className="flex flex-col space-y-1 ml-5">
            <div className="text-3xl text-center font-semibold leading-7 mb-3">
               <MdStringRenderer source={props.title} />
            </div>
            <div className="text-center space-y-3 leading-4 lg:ml-5 lg:px-5">
               {props.allways ? (
                  <MdStringRenderer source={props.allways} />
               ) : (
                  <>
                     {props.businessWeek ? (
                        <MdStringRenderer source={props.businessWeek} />
                     ) : (
                        <>
                           <MdStringRenderer source={props.monday} />
                           <MdStringRenderer source={props.tuesday} />
                           <MdStringRenderer source={props.wednesday} />
                           <MdStringRenderer source={props.thursday} />
                           <MdStringRenderer source={props.friday} />
                        </>
                     )}

                     {props.weekend ? (
                        <MdStringRenderer source={props.weekend} />
                     ) : (
                        <>
                           <MdStringRenderer source={props.saterday} />
                           <MdStringRenderer source={props.sunday} />
                        </>
                     )}
                  </>
               )}
            </div>
         </div>
      </div>
   );
};
