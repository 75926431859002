import React from 'react';
import { MdStringRenderer } from '@matix/utils';
import { ImageProps, Image } from '@matix/ui';

export const HighlightItem = ({ title, description, img }: Props) => {
   return (
      <div className="flex flex-col my-5 mx-auto w-auto md:w-1/2 lg:w-1/5 content-center text-center">
         <div className="w-20 mx-auto">
            <Image {...img} />
         </div>
         <div className="font-bold my-2">
            <MdStringRenderer source={title} />
         </div>
         <div className="my-5">
            <MdStringRenderer source={description} />
         </div>
      </div>
   );
};

interface Props {
   title: string;
   description: string;
   img: ImageProps;
   className?: string;
}
