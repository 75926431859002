import React from 'react';
import { MdStringRenderer } from '@matix/utils';
import { ImageProps, Image } from '@matix/ui';

export interface ItemMission {
   msg: string;
   img: ImageProps;
}

export interface Props {
   title: string;
   items: ItemMission[];
}

export const Mission = (props: Props) => {
   return (
      <div>
         <div className="text-4xl text-center py-5 my-5">
            <MdStringRenderer source={props.title} />
         </div>
         <div className="flex flex-col  items-start lg:flex-row">
            {props.items &&
               props.items.map((item: ItemMission) => (
                  <div className="flex flex-col mx-auto mb-10 flex-1">
                     <div className="mx-auto w-20 mb-5 p-0">
                        <Image {...item.img} />
                     </div>
                     <div className="text-center">
                        <MdStringRenderer source={item.msg} />
                     </div>
                  </div>
               ))}
         </div>
      </div>
   );
};
