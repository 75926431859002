/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import GatsbyImage, { FluidObject, GatsbyImageOptionalProps, FixedObject } from 'gatsby-image';
import { LocalImagesContext, IsMobile, isSSR } from '@matix/utils';

export interface ImageProps extends ImageFluidProps, ImageFixedProps {
   url?: string;
   className?: string;
   text?: string;
}

interface ImageFluidProps extends GatsbyImageOptionalProps {
   fluid?: FluidObject | FluidObject[];
}

interface ImageFixedProps extends GatsbyImageOptionalProps {
   fixed?: FixedObject | FixedObject[];
}

const urlToUniqueName = (url) => {
   return url.split('').reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
   // TODO: I wanted to use public url as name but it needs to fit some requirements to be store in azure..
   // i.e. no /, nothing after extension if extension is provided i.e. .jpg%456456
   // return url
   //    .replace(/https:\/\//, '')
   //    .replace(/http:\/\//, '')
   //    .replace(/\//g, '-');
};

export const Image = (props: ImageProps) => {
   const localImagesCtx = useContext(LocalImagesContext);
   const nullImg = localImagesCtx.images.filter((img) => !img);
   const { url } = props;
   const getImageFromContext = (key) => {
      let img;
      const isMobile = IsMobile();
      if (key.startsWith('http')) {
         const imgKey = localImagesCtx.basePath + '/' + urlToUniqueName(key);
         img = localImagesCtx.images.find((localImage) => {
            if (localImage) {
               return localImage.url === imgKey;
            }
            return false;
         });
      } else {
         img = localImagesCtx.images.find((localImage) => localImage.name === key && localImage.IsMobile === isMobile);
      }
      if (!img && !isSSR()) {
         console.debug(`The image with url ${key} is not processed and optimized by Gatsby. Consider adding url to local build.`);
         return null;
      }
      return img;
   };

   const imgFromContext = url && getImageFromContext(url);
   const isFromCtx = imgFromContext === null ? false : true;
   if (isFromCtx && imgFromContext && imgFromContext.childImageSharp) {
      return <GatsbyImage {...props} fluid={imgFromContext.childImageSharp.fluid} fixed={imgFromContext.childImageSharp.fixed}></GatsbyImage>;
   } else {
      return (
         <>
            {url ? (
               <img alt={props.alt} src={url} className={props.className} draggable="false"></img>
            ) : (
               <GatsbyImage {...props} fluid={props.fluid} fixed={props.fixed}></GatsbyImage>
            )}
         </>
      );
   }
};
