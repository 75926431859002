import React from 'react';

export const HearthAddSvg = (props: React.SVGProps<SVGSVGElement>) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" {...props} viewBox="0 0 24 24">
         <path
            fill="currentColor"
            d="M15.653 19.415C14.491 20.556 13.264 21.746 12 23 5.57 16.619 0 11.853 0 7.192 0 3.187 3.098 1 6.281 1 8.478 1 10.715 2.042 12 4.248c1.279-2.195 3.521-3.238 5.726-3.238C20.903 1.01 24 3.181 24 7.192c0 1.269-.424 2.546-1.154 3.861l-1.483-1.484c.403-.836.637-1.631.637-2.377 0-2.873-2.216-4.182-4.274-4.182-3.257 0-4.976 3.475-5.726 5.021-.747-1.54-2.484-5.03-5.72-5.031C3.965 2.999 2 4.516 2 7.192c0 3.442 4.742 7.85 10 13l2.239-2.191 1.414 1.414zM23 14h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z"
         ></path>
      </svg>
   );
};
