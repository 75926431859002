import { ContactSection, Form, ContactInfo, OpenHours } from '@briqueconcept/ui';
import { Map } from '@matix/ui';
import FooterMdx from "..\\..\\..\\..\\src\\markdown\\fr\\footer.mdx";
import HeaderMdx from "..\\..\\..\\..\\src\\markdown\\fr\\header.mdx";
import * as React from 'react';
export default {
  ContactSection,
  Form,
  ContactInfo,
  OpenHours,
  Map,
  FooterMdx,
  HeaderMdx,
  React
};