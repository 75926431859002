import React, { useState } from 'react';
import { Image, ImageProps } from '../image/image';
import { MdStringRenderer } from '@matix/utils';
import { ChevronLeft } from '../awesome-icon/chevron-left';
import { ChevronRight } from '../awesome-icon/chevron-right';
import c from 'classnames';

export interface SlideCarousel {
   msg: string;
   img: ImageProps;
}

interface Props {
   slides: SlideCarousel[];
   title: string;
}

export const Carousel = (props: Props) => {
   const { slides } = props;
   const [activeIdx, setActiveIdx] = useState(0);

   const changeSlide = (idx: number) => {
      if (idx > slides.length - 1) {
         setActiveIdx(slides.length - 1);
      } else if (idx < 0) {
         setActiveIdx(0);
      } else {
         setActiveIdx(idx);
      }
   };

   return (
      <div className="flex flex-col justify-center text-center mx-auto my-10 w-full lg:w-3/5">
         <div className="text-2xl">
            <MdStringRenderer source={props.title} />
         </div>
         <div className="relative py-10 mx-auto w-full md:w-4/5 md:h-128 lg:h-144">
            <div className="flex absolute top-0 left-0 mx-auto right-0 w-112 h-112 md:h-full md:w-full">
               <div className="flex items-center justify-start w-1/2 h-full">
                  <button
                     className="outline-none bg-transparent font-bold rounded-full hover:shadow-2xl w-8 h-12 -ml-8"
                     onClick={() => changeSlide(activeIdx - 1)}
                  >
                     <ChevronLeft className="w-5 text-black mx-auto" />
                  </button>
               </div>
               <div className="flex items-center justify-end w-1/2 h-full">
                  <button
                     className="outline-none bg-transparent font-bold rounded-full hover:shadow-2xl w-8 h-12 -mr-8"
                     onClick={() => changeSlide(activeIdx + 1)}
                  >
                     <ChevronRight className="w-5 text-black mx-auto" />
                  </button>
               </div>
            </div>

            <div className="relative h-112 w-112 mx-auto md:h-full md:w-full">
               {slides &&
                  slides.map((slide: SlideCarousel, idx: number) => (
                     <div
                        key={idx}
                        className={c(
                           'absolute top-0 left-0 transition duration-1000 ease-out w-full ',
                           idx !== activeIdx ? 'opacity-0 -z-10' : 'opacity-100 z-0'
                        )}
                     >
                        <div className="overflow-hidden max-h-112 lg:max-h-144">
                           <Image {...slide.img} />
                        </div>
                        <div className="pt-2 lg:pt-5">
                           <MdStringRenderer source={slide.msg} />
                        </div>
                     </div>
                  ))}
            </div>
            <div className="w-full flex items-center justify-center px-4">
               {slides &&
                  slides.map((slide: SlideCarousel, idx: number) => (
                     <div key={idx}>
                        <button
                           className={c(
                              'flex-1 w-10 h-2 mx-2 mb-0 rounded-full overflow-hidden transition-colors duration-200 ease-out hover:shadow-lg',
                              idx !== activeIdx ? 'bg-primary-600' : 'bg-secondary-600'
                           )}
                        ></button>
                     </div>
                  ))}
            </div>
         </div>
      </div>
   );
};
