import React from 'react';
import { ImageProps, Image } from '@matix/ui';
import { LinkMatix, MdStringRenderer } from '@matix/utils';
import { FavoriteHearth } from './favorite-hearth';
export interface ProductSummaryCardLightProps extends React.HTMLAttributes<HTMLDivElement> {
   name: string;
   imgMain: ImageProps;
   description?: string;
   productLink: string;
   category?: string;
}
export const ProductSummaryCardLight = (props: ProductSummaryCardLightProps) => {
   const { name, imgMain, productLink } = props;

   const btnDetailText = 'Voir détails';
   return (
      <div className="flex flex-col w-full">
         <div className="relative h-52">
            <Image {...imgMain} className="absolute inset-0 w-full h-full object-cover bg-gray-100 xl:rounded-lg" />
            <FavoriteHearth className="absolute -right-5 -top-5 h-12 w-12" favorite={{ url: productLink }}></FavoriteHearth>
         </div>
         <div className="text-center text-xl py-3 flex-grow">{name}</div>
         <div className="mx-auto">
            <LinkMatix to={productLink}>
               <button
                  type="button"
                  className="shadow bg-primary focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded hover:bg-secondary hover:text-black"
               >
                  <MdStringRenderer source={btnDetailText} />
               </button>
            </LinkMatix>
         </div>
      </div>
   );
};
