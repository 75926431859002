import { CarouselSpring } from '@matix/ui';
import { MdStringRenderer } from '@matix/utils';
import React from 'react';
import { RealisationProduct } from '../data/product';
import { ProductSummaryCardLight } from './product-summary-card-light';
export interface ProductRealisationProps extends React.HTMLAttributes<HTMLDivElement> {
   products: RealisationProduct[];
   title: string;
}
export const ProductRealisationSection = (props: ProductRealisationProps) => {
   const { products, title } = props;
   if (!products?.length) return null;
   const slides = products.map((p) => ({ image: { url: p.imgLink }, text: '' }));
   return (
      <div className="pb-5">
         {products && products.length ? (
            <div className=" container mx-auto bg-gray-100 shadow-xl pt-8 md:px-16">
               <div className="text-3xl text-center lg:text-6xl  lg:py-16">
                  <MdStringRenderer source={title} />
               </div>
               <div className="md:w-4/5 lg:w-3/5 lg:h-2/5 mx-auto flex justify-center">
                  <CarouselSpring
                     className="w-full h-full"
                     withFullScreenMode={true}
                     slides={slides}
                     withThumbnails={true}
                     withControls={true}
                     withLoop={false} /**There is a bug when setting this to true and using thumbnails */
                  ></CarouselSpring>
               </div>
            </div>
         ) : null}
      </div>
   );
};
