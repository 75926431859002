import { HeroWithImage, HighlightSection, HighlightItem, CardGroup } from '@briqueconcept/ui';
import { CarouselSpring } from '@matix/ui';
import FooterMdx from "..\\..\\..\\..\\src\\markdown\\fr\\footer.mdx";
import HeaderFrMdx from "..\\..\\..\\..\\src\\markdown\\fr\\header.mdx";
import * as React from 'react';
export default {
  HeroWithImage,
  HighlightSection,
  HighlightItem,
  CardGroup,
  CarouselSpring,
  FooterMdx,
  HeaderFrMdx,
  React
};