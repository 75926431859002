import React, { useState } from 'react';
import c from 'classnames';
import { CartSvg, LogoSvg, CloseSvg } from '../svg';
import { Heart } from '@matix/ui';
import { MdStringRenderer } from '@matix/utils';
interface Props {
   title: string;
   link?: string;
   questionText: string;
   callUsText: string;
   favoriteLink: string;
   children: React.ReactNode;
}
export const HeaderMobile = (props: Props) => {
   const [open, setOpen] = useState(false);
   return (
      <div
         className={c(
            'absolute top-0 inset-x-0 transition transform origin-top-right sm:hidden duration-200 ',
            open ? 'opacity-100 ease-out translate-y-3 z-50' : 'opacity-0 ease-in'
         )}
      >
         <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
               <div className="flex items-center justify-between">
                  <LogoSvg />
                  <div className="-mr-2">
                     <button
                        onClick={() => setOpen(!open)}
                        type="button"
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                     >
                        <span className="sr-only">Close menu</span>

                        <CloseSvg />
                     </button>
                  </div>
               </div>
               <div className={c('mt-6', !open ? 'hidden' : 'block')}>
                  <nav className="grid gap-y-8">{props.children}</nav>
               </div>
            </div>
            <div className={c('py-6 px-5 space-y-6', !open ? 'hidden' : 'block')}>
               <div>
                  <div className="w-full h-8 max-h-8 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary hover:text-black">
                     <MdStringRenderer source={props.favoriteLink} className="flex m-0 p-0 h-6 max-h-6">
                        <Heart className="ml-4 w-6 fill-current text-red-600" />
                     </MdStringRenderer>
                  </div>
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                     {props.questionText} &nbsp;
                     <a href="tel:418-832-6959" className="text-indigo-600 hover:text-indigo-500">
                        {props.callUsText}
                     </a>
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};
