import React from 'react';
import { ReleatedProduct } from '../data/product';
import { ProductSummaryCardLight } from './product-summary-card-light';
import { MdStringRenderer } from '@matix/utils';
export interface ProductRelatedProps extends React.HTMLAttributes<HTMLDivElement> {
   relatedProducts: ReleatedProduct[];
   title: string;
}
export const ProductRelatedSection = (props: ProductRelatedProps) => {
   const { relatedProducts, title } = props;

   return (
      <div>
         {relatedProducts && relatedProducts.length ? (
            <div className=" container mx-auto bg-gray-100 shadow-xl pt-16 px-6">
               <div className="text-3xl text-center lg:text-6xl  lg:py-16">
                  <MdStringRenderer source={title} />
               </div>
               <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-5">
                  {relatedProducts.map((product) => (
                     <ProductSummaryCardLight
                        key={product.url}
                        imgMain={{ url: product.imgLink }}
                        name={product.name}
                        productLink={product.slug}
                     ></ProductSummaryCardLight>
                  ))}
               </div>
            </div>
         ) : null}
      </div>
   );
};
