import { ProductDetail, ProductRelatedSection, ProductRealisationSection } from '@briqueconcept/ui';
import { Carousel } from '@matix/ui';
import FooterMdx from "..\\..\\..\\..\\src\\markdown\\fr\\footer.mdx";
import HeaderMdx from "..\\..\\..\\..\\src\\markdown\\fr\\header.mdx";
import * as React from 'react';
export default {
  ProductDetail,
  ProductRelatedSection,
  ProductRealisationSection,
  Carousel,
  FooterMdx,
  HeaderMdx,
  React
};