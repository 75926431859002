import React from 'react';
import ReactMarkdown from 'react-markdown';
import { LinkMatix } from '../links';
//import gfm from 'remark-gfm'
export const MdStringRenderer = (props: any) => {
   const linkChild = props.children;
   const renderers = {
      link: (propsLink: any) => {
         return (
            <LinkMatix to={propsLink.href}>
               <div className={props.className}>
                  {propsLink.children}
                  {linkChild}
               </div>
            </LinkMatix>
         );
      },
   };

   return <ReactMarkdown renderers={renderers} children={props.source}></ReactMarkdown>;
};
