import React from 'react';
import { Image, CarouselSpring } from '@matix/ui';
import { MdStringRenderer, isSSR } from '@matix/utils';
import c from 'classnames';
import { useContext } from 'react';
import { StoreContext, StoreContextProps } from '../contexts/store-context';
import { Breadcrumb, Product } from '../data';
import { BreadcrumbMtx } from '../breacrumb/breadcrumb';
import { FavoriteHearth } from './favorite-hearth';
export interface ProductDetailProps extends React.HTMLAttributes<HTMLDivElement> {
   product: Product & { fields: { slugProduct: string } };
   spec: string;
   type: string;
   app: string;
   seoData: any;
}
export const ProductDetail = (props: ProductDetailProps) => {
   const { product } = props;

   const variantMap = { 1: 1, 2: 2, 3: 3, 4: 2, 5: 3, 6: 3, 7: 3, 8: 4 };
   const { getLogo, getPageCategoryUrl, getPageVendorUrl } = useContext(StoreContext) as StoreContextProps;

   const applications = product.applications ? product.applications.split(',') : ['N/A'];
   const prdLink = isSSR() ? '/' : window.location.pathname;

   return (
      <div className="container max-w-screen-2xl mx-auto">
         <div className="sticky top-0 z-40">
            <BreadcrumbMtx items={props.seoData.breadcrumbs}></BreadcrumbMtx>
         </div>
         <div className="flex flex-col xl:grid xl:grid-cols-4 xl:px-8 xl:py-12 xl:gap-x-14 xl:pt-14 bg-white">
            <div className="mx-2 xl:row-start-1 flex flex-col">
               <div className="xl:leading-7">{getLogo({ provider: product.provider, className: 'w-40 xl:w-full' })}</div>
            </div>

            <div className="mx-2 xl:col-span-2 xl:row-start-1 xl:col-start-2">
               <div className="text-2xl font-bold pb-10 xl:text-5xl italic xl:leading-10 xl:text-black ">
                  <MdStringRenderer source={product.name} />
               </div>
            </div>
            <div className="xl:row-start-1 xl:col-start-4">
               <div className="absolute z-10 right-5 xl:relative xl:float-right">
                  <FavoriteHearth
                     className="w-16 h-16"
                     favorite={{
                        url: prdLink,
                        image: { url: product.imgLink },
                        productName: product.name,
                        category: product.category,
                        provider: product.provider,
                     }}
                  ></FavoriteHearth>
               </div>
            </div>

            <div className="flex h-full pt-5 xl:row-span-2 xl:row-start-2 ">
               <div className="w-full h-56 xl:h-full grid grid-cols-1 grid-rows-1 gap-2">
                  <div className="relative">
                     <CarouselSpring
                        className="absolute inset-0 w-full h-full "
                        withFullScreenMode={true}
                        slides={[{ image: { url: product.imgLink }, text: product.name }]}
                     ></CarouselSpring>
                  </div>
               </div>
            </div>

            <div className="mx-2 xl:row-start-2 xl:col-start-2 xl:col-span-3">
               <div className="font-bold text-2xl mb-5">
                  <MdStringRenderer source={props.spec} />
               </div>
               <div className="text-gray-600 text-justify text-base font-light px-4 rounded-lg lg:text-left">{product.description}</div>
            </div>

            <div className="mx-2 xl:row-start-3 xl:row-span-2 xl:col-start-2 xl:col-span-2">
               <div className="font-bold text-2xl my-5">
                  <MdStringRenderer source={props.type} />
               </div>
               <div
                  className={c(
                     'w-full rounded-3xl transform shadow-lg p-5 grid grid-cols-2 xl:gap-4',
                     `xl:grid-cols-${variantMap[product.variant?.length]}`
                  )}
               >
                  {product.variant?.map((variant) => (
                     <div className="flex flex-col">
                        <Image url={variant.imgLink} className="h-1/2 w-1/2 mx-auto object-cover rounded-lg bg-gray-100 xl:h-24" />
                        <p className="text-center py-5">{variant.name}</p>
                     </div>
                  ))}
               </div>
            </div>
            <div className="mx-2 xl:row-start-3 xl:col-start-4 lg:h-96">
               <div className="font-bold text-2xl my-5">
                  <MdStringRenderer source={props.app} />
               </div>
               <ul className="font-light list-disc text-xl p-4 ml-4">
                  {applications.map((app: string, idx: number) => (
                     <li key={idx}>
                        <MdStringRenderer source={app} />
                     </li>
                  ))}
               </ul>
            </div>
         </div>
      </div>
   );
};
